/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.dialogue-info-modal-multiplechoice{margin-left:0px;margin-right:0px;margin-bottom:25px}.dialogue-info-modal-multiplechoice .title-container{padding-left:0px;padding-right:20px;background-color:#0f274a;border-radius:5px;width:inherit;position:relative;cursor:pointer}.dialogue-info-modal-multiplechoice .title-container>.menu-arrow-wrapper{position:absolute;right:10px;top:17px;transform:translateY(-50%)}.dialogue-info-modal-multiplechoice .title-container>.menu-arrow-wrapper>.menu-arrow{display:inline-block;border-color:#fff;border-style:solid;border-width:0 2px 2px 0;padding:2.5px;vertical-align:middle;transition:transform .3s;transform:rotate(45deg)}.dialogue-info-modal-multiplechoice .title-container.open>.menu-arrow-wrapper .menu-arrow{transform:rotate(225deg)}.dialogue-info-modal-multiplechoice span.title{display:inline-block;margin-left:20px;margin-right:20px;padding-top:5px;padding-bottom:5px;text-align:left;text-shadow:none;font-size:20px;color:#fff;position:relative}.dialogue-info-modal-multiplechoice span.title-correct-answer{color:#90ee90;text-shadow:none}.dialogue-info-modal-multiplechoice span.title-incorrect-answer{color:#ff0808;text-shadow:none}.dialogue-info-modal-multiplechoice .dialogue-info-modal-header{text-align:center;text-shadow:gray 2px 1px;color:#fff}.dialogue-info-modal-multiplechoice .dialogue-info-modal-addendum{color:#fff}.dialogue-info-modal-multiplechoice .exercise-container{position:initial;transform:none}