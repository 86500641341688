/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.exercise-editor{position:relative;text-align:right;padding:10px 10px 10px 10px;background-color:#fff;box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);margin-bottom:25px;margin-top:25px}.exercise-editor .exercise-editor-title{text-align:left}.exercise-editor .exercise-editor-title input{width:100%}.exercise-editor .exercise-editor-control-buttons{cursor:pointer;position:absolute;top:5px;right:10px;filter:drop-shadow(1px 1px 2px black)}.exercise-editor .exercise-editor-control-buttons .fa-plus:hover{color:#36ef36}.exercise-editor .exercise-editor-control-buttons .fa-plus{color:#279e27}.exercise-editor .exercise-editor-control-buttons .fa-trash-alt:hover{color:#e61b1b}.exercise-editor .exercise-editor-control-buttons .fa-trash-alt{color:#a91515}