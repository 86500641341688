/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.dialogue-info-modal-multiplechoiceoption{padding-left:35px;background-color:#222626;border-radius:5px;padding-top:10px;padding-bottom:10px;margin-bottom:10px}.dialogue-info-modal-multiplechoiceoption label.correct-answer{color:#90ee90}.dialogue-info-modal-multiplechoiceoption label.incorrect-answer{color:red}.dialogue-info-modal-multiplechoiceoption label{text-shadow:none}.dialogue-info-modal-multiplechoiceoption .explanation-text{font-style:italic;text-shadow:none}.dialogue-info-modal-multiplechoiceoption .incorrect-answer.form-check-input:checked{background-color:red;border-color:red}.dialogue-info-modal-multiplechoiceoption .correct-answer.form-check-input:checked{background-color:#90ee90;border-color:#90ee90}.dialogue-info-modal-multiplechoiceoption .form-check-input:checked:disabled{opacity:1}.dialogue-info-modal-multiplechoiceoption .form-check-input:disabled~.form-check-label,.dialogue-info-modal-multiplechoiceoption .form-check-input[disabled]~.form-check-label{opacity:1}