/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.answer-line-editor{text-align:left;margin-bottom:5px;position:relative}.answer-line-editor .answer-line-editor-radio{text-align:right}.answer-line-editor input[type=text]{width:100%}.answer-line-editor textarea{width:90%}.answer-line-editor .answer-line-editor-delete{cursor:pointer;position:absolute;top:5px;right:10px;filter:drop-shadow(1px 1px 2px black);width:initial}.answer-line-editor .answer-line-editor-delete.fa-trash-alt:hover{color:#e61b1b}.answer-line-editor .answer-line-editor-delete.fa-trash-alt{color:#a91515}