/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */
.dialogue-editor-controls {
    background-color: #62e8e87a;
    margin-left: 0px;
    margin-right: 0px;
    padding: 5px;

    .btn {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    .row {
//         padding: 5px;
    }

    .file-input {
        display: none;
    }

    .controls-buttons-wrapper {
        text-align: left;
        padding-left: 20px;

        button {
            margin-top: 10px;
        }
    }

    .controls-input-wrapper {

        padding-left: 20px;

        input.title-input {
            margin-top: 10px;
            max-width: 85%;
        }
    }

    .base64-info-icon-wrapper {
        margin-top: 10px;
        position: relative;
        max-width: 85%;
        margin-bottom: 8px;
    }

    .base64-info-icon {
        position: absolute;
        top: 50%;
        right: -33px;
        transform: translateY(-50%);
        filter: drop-shadow(1px 1px 2px black);
    }

    .base64-info-icon:hover {
        border: solid 1px #00d7ec;
        border-radius: 50%;
        cursor: pointer;
    }
}

.dialogue-editor-controls-outer {

    .ReactModal__Overlay {

        background: #0000007d!important;

        .dialogue-modal-inner {
            max-width: 90%;
            max-height: 90%;
            width: 500px;
            height: 300px;
            top: 50%;
            transform: translateY(-50%);
            position: relative;
            background-color: white;
            margin-left: auto;
            margin-right: auto;
            border-radius: 15px;
            padding: 25px;

            .dialogue-modal-close-button-icon {

                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 2em;
                cursor: pointer;

                &:hover {
                    color: grey;
                }
            }

            .dialogue-editor-controls-modal-innertext {
                top: 50%;
                position: relative;
                transform: translateY(-50%);
                color: #07032d;
                font-weight: 500;
            }
        }
    }
}
