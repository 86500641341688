/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.information-view-container{position:absolute;top:0%;height:100%;width:100%}.information-view-container .information-view-inner-container{position:relative;width:100%;height:100%}.information-view-container .information-view-inner-container .information-block{text-align:initial;position:absolute;padding:40px;max-width:700px;width:90%;height:70%;overflow-x:hidden;overflow-y:scroll;background-color:#308379;color:#fff;border-radius:15px;top:50%;left:50%;transform:translate(-50%, -50%)}.information-view-container .information-view-inner-container .information-block img{max-width:100%}.information-view-container .information-view-inner-container .information-block a{color:purple}.information-view-container .information-view-inner-container .information-block::-webkit-scrollbar{width:10px}.information-view-container .information-view-inner-container .information-block::-webkit-scrollbar-thumb{background:#d3d3d3;border-radius:20px}.information-view-container .information-view-inner-container .information-block::-webkit-scrollbar-thumb:hover{background:#fff}.information-view-container .information-view-inner-container .information-block blockquote{background:#f9f9f9;border-left:10px solid #ccc;margin:1.5em 10px;padding:.5em 10px;quotes:"“" "”" "‘" "’"}.information-view-container .information-view-inner-container .information-block blockquote p{display:inline}