/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.side-nav{padding-top:40px;background-color:#0c1e35;width:310px;height:100%;position:relative;top:0;left:0;transition:width .5s;color:#b3b8d4;z-index:1;text-overflow:ellipsis;white-space:nowrap;display:flex;align-items:stretch;flex-direction:column}.side-nav hr{margin-bottom:0px}.side-nav .side-nav-inner{overflow:hidden;overflow-x:hidden;overflow-y:scroll}.side-nav .side-nav-inner::-webkit-scrollbar{width:5px}.side-nav .side-nav-inner::-webkit-scrollbar-thumb{background:#375984;border-radius:20px}.side-nav .side-nav-inner::-webkit-scrollbar-thumb:hover{background:#b30000}.side-nav li{text-align:left}.side-nav.collapsed{width:0px}.side-nav ul{list-style-type:none;padding:0;margin:0}.side-nav .menu-toggle-button{position:absolute;top:10px;left:10px;z-index:1;background-color:#224571;border-color:#224571;box-shadow:none}.side-nav .menu-toggle-button:active{box-shadow:none;background-color:#224571 !important;border-color:#224571 !important}.side-nav .menu-toggle-button:focus{box-shadow:none;background-color:#224571 !important;border-color:#224571 !important}.side-nav .menu-toggle-button:hover{color:#fff;background-color:#224571;border-color:#224571}