/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.link-container{cursor:pointer;padding-left:20px;text-align:left}.link-container .ukraine-flag-image{width:30px;height:20px;margin-right:20px}.link-container a{color:#b3b8d4;text-decoration:none}.link-container a:hover{color:#fff}.link-container:hover{color:#fff}