/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */
.dialogue-editor-row-line-editor {
    background-color: #FEFEFE;
    border-radius: 5px;
    margin: 15px 5px 25px 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .form-radio-container {

        text-align: left;
        margin-left: 5px;

        input[type=radio] {
            margin-right: 5px;
        }
    }

    .dialogue-editor-audio-source-file-open-container {

        position: relative;

        .dialogue-editor-audio-source-file-open {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px
        }

        .audio-file-input {
            display: none;
        }
    }
}

.dialogue-editor-row-controls-button {
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: 15%;
    filter: drop-shadow(1px 1px 2px black);

    .fa-plus:hover {
        color: #36ef36;
    }

    .fa-plus {
        color: #279e27;
    }

    .fa-trash-alt:hover {
        color: #e61b1b;
    }

    .fa-trash-alt {
        color: #a91515;
    }
}
