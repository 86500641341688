/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.dialogue-wrapper{position:absolute;top:0;left:0;width:100%;height:90%;overflow:scroll;padding-top:55px;overflow-x:hidden}.dialogue-wrapper::-webkit-scrollbar{width:0;background:rgba(0,0,0,0)}@media(min-height: 605px){.dialogue-wrapper{padding-top:0px}}