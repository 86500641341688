/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.youtube-wrapper{max-width:880px;margin:auto}.youtube-wrapper .youtube-embed-container{position:relative;padding-bottom:56.25%;height:0;overflow:hidden;max-width:100%}.youtube-wrapper .youtube-embed-container iframe{position:absolute;top:0;left:0;width:100%;height:100%}