/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.questions-container{text-align:left;padding-left:10px;padding-right:10px;margin-top:30px;text-shadow:gray 2px 1px;color:#fff}.questions-container>.title{text-align:left;text-shadow:gray 2px 1px;font-size:1.7em;color:#fff;margin-top:30px;margin-bottom:30px}.questions-container button{margin-bottom:10px}.questions-container .dialogue-info-modal-header{text-align:center;text-shadow:gray 2px 1px;color:#fff}.questions-container .dialogue-info-modal-addendum{color:#fff}.questions-container .exercise-container{position:initial;transform:none}