/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.answer-block-editor{padding:12px;background-color:gray;color:#fff;box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);position:relative}.answer-block-editor .answer-block-editor-header{margin-bottom:15px}.answer-block-editor .answer-block-editor-header .answer-block-editor-header-radio{text-align:right}.answer-block-editor .answer-block-editor-header .answer-block-editor-header-text{text-align:left}.answer-block-editor .answer-block-editor-header .answer-block-editor-header-correction-text{text-align:left}.answer-block-editor .answer-block-editor-add-line{cursor:pointer;position:absolute;top:10px;right:10px;filter:drop-shadow(1px 1px 2px black)}.answer-block-editor .answer-block-editor-add-line.fa-plus:hover{color:#36ef36}.answer-block-editor .answer-block-editor-add-line.fa-plus{color:#279e27}