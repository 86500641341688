/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */.dialogue-voc-exercise-modal-bg table{color:#2673be !important;background-color:#fefefe;width:97%;margin:auto;margin-top:15px}.dialogue-voc-exercise-modal-bg table td{color:#2673be !important}.dialogue-voc-exercise-modal-bg .dialogue-modal-close-button-icon:hover{color:gray}.dialogue-voc-exercise-modal-bg>div{background-color:rgba(0,0,0,0) !important}.dialogue-voc-exercise-modal-bg>div>div{inset:10px !important;padding:50px 0 0 0 !important;text-align:center;background-color:teal !important;color:#fff}.dialogue-voc-exercise-modal-bg>div>div::-webkit-scrollbar{width:0;background:rgba(0,0,0,0)}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-bulk-input{border:1px solid #90ee90;background-color:rgba(0,0,0,0);height:200px;padding:10px;white-space:pre;overflow-wrap:normal;overflow-x:scroll;color:#fff}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-bulk-input::-webkit-scrollbar{width:0;background:rgba(0,0,0,0)}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-bulk-input-left{text-align:right;width:100%}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-bulk-input-right{width:100%}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-column-inputs{padding-left:20px;padding-right:20px}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-base64{background-color:rgba(0,0,0,0);color:#fff;width:85%;height:200px;border:1px solid #90ee90}.dialogue-voc-exercise-modal-bg .vocabulary-trainer-base64::-webkit-scrollbar{width:0;background:rgba(0,0,0,0)}