/*!
 * Copyright 2024 André Schepers
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */
@import './EditorRow/style.scss';

.dialogue-editor-edit-section {

    .dialogue-editor-edit-section-audio-input {
        text-align: left;
        margin: 15px;
        background-color: gray;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);

        .audio-source-file-open-container {

            position: relative;

            .audio-file-input {
                display: none;
            }

            .audio-source-file-open {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
            }
        }

        input, input:hover, input:active, input:focus-visible, textarea, textarea:hover, textarea:focus-visible, textarea:focus-visible {
            margin-left: 25px;
            width: initial;
            color: white;
            background-color: teal;
            border: none;
            box-shadow: none;
            outline: none;
            width: 70%;
            border-radius: 5px;
            padding-left: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        textarea {
            overflow: hidden;
        }

        input::placeholder {
            color: coral;
            opacity: 1;
        }
    }
}

